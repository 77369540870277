:root {
  --blue: rgb(14, 45, 94);
  --red: rgb(255, 73, 69);
  --lightblue: #519bf4;
  --darkgrey: #1b1d21;
  --lightgrey: #232327;
  --bordercolor: #313335;
  --textcolor: #ffffff8e;
}
.wrapper {
  width: 80%;
  margin: 0 auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: unset;
  margin: 0;
}
body {
  font-family: "mont-regular";
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  display: none;
}
img {
  width: 100%;
  display: block;
}
@font-face {
  font-family: "mont-light";
  src: url(../fonts/Mont-Light.woff);
}
@font-face {
  font-family: "mont-bold";
  src: url(../fonts/Mont-Bold.woff);
}
@font-face {
  font-family: "mont-bolder";
  src: url(../fonts/Mont-Heavy.woff);
}
@font-face {
  font-family: "mont-regular";
  src: url(../fonts/Mont-Regular.woff);
}
@font-face {
  font-family: "mont-medium";
  src: url(../fonts/Mont-SemiBold.woff);
}
audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #a9a9a9;
  color: #a0276e;
}
.ql-editor {
  height: 150px !important;
}
@media all and (max-width: 1440px) {
  .wrapper {
    width: 90%;
  }
}
ul {
  list-style: none;
}
/* * PAGINATION */

.pagination {
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: #48538d;
  flex-wrap: wrap;
}
.pagination a {
  padding: 4px 14px;
  border-radius: 5px;
  color: #161619;
  background-color: #fff;
  font-size: 13px;
  margin: 0 5px 5px;
  display: inline-block;
}
.pagination li.break a {
  display: inline-block;
}
.previous .pagination__link {
  background-color: unset;
  color: #48538d;
}
.next .pagination__link {
  background-color: unset;
  color: #48538d;
}
.pagination__link {
  font-weight: bold;
}
.pagination__link--active a {
  color: #fff !important;
  background: #48538d;
}
.pagination__link--disabled a {
  cursor: not-allowed;
}
.active_link_classname {
  color: #fff;
  background: #48538d;
}
.ecAamZ {
  padding: 10px 0 15px !important;
}

/* pagination_ends */
