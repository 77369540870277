/* alert message */
.alert {
  width: 50%;
  margin: 20px auto;
  padding: 30px;
  position: absolute;
  z-index: 1000000000;
  top: 0;
  border-radius: 5px;
  box-shadow: 0 0 15px 5px #cccccc23;
}
.close {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  right: 15px;
  top: 15px;
  text-align: center;
  font-size: 1.6em;
  cursor: pointer;
}
@mixin alert($name, $bgColor) {
  $accentColor: darken($bgColor, 50);
  .#{$name} {
    background-color: #{$bgColor};
    border-left: 5px solid $accentColor;
    .close {
      border-color: $accentColor;
      color: $accentColor;
    }
  }
}
@include alert(danger-alert, #f7a7a3);

// border: 1px solid #7c4747;
// border: 1px solid #4e7b4e;
